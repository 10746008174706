// @import "normalise";
// @import "typography";
// @import "layout";
// @import "screen";
// @import "layout";
// Colors
// color variables
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500&display=swap');
$color-palette:(theme: (50: #fff, 100: #FAFAFA, 120: #EDEDED, 130: #E6EBF2, 150: #999999, 200: #666666, 250: #333333, 500: #404CDC, 600: #40C9DC, 700: #FD692A, 900: #000000, ));
$theme: map-get($color-palette,
theme);
// fonts
$font-family: (font: (rg: 'Montserrat-Regular', md: 'Montserrat-Medium', sm: 'Montserrat-SemiBold', bd: 'Montserrat-Bold', rg01: 'george'));
$font: map-get($font-family,
font);
$imageurl: "../images";
$fonturl: "../fonts";
$blue: #2879c9;
$green: #B0CB1F;
$primary-color: $blue;
$secondary-color: $green;
// Fonts
$serif: "Lora",
"Playfair Display",
Georgia,
serif;
$sans-serif: "Roboto",
"Source Sans Pro",
"Open Sans",
Arial,
sans-serif;
$monospace: "Inconsolata",
monospace;
$primary-font: $sans-serif;
$secondary-font: $serif;
// $font:Raleway,
// sans-serif;
// $bd: 600;
$text-color: #4d5156;
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

$grid-breakpoints: (xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1200px,
xxl: 1400px);
body {
    // font-family: map-get($sans-serif, $text-color);
    line-height: 1.5;
    font-size: 16px;
    color: $text-color;
}

img {
    max-width: 100%;
}

@media (max-width: 1599px) {
    body {
        padding-top: 75px
    }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
    body {
        padding-top: 68px
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    body {
        padding-top: 57px
    }
}

@media (max-width: 767.98px) {
    body {
        font-size: 14px;
        padding-top: 52px
    }
}

@media (min-width:576px) {
    .container {
        max-width: 540px
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px
    }
}

@media screen and (min-width:1366px) {
    .container {
        max-width: 1280px;
    }
}

.commonSection {
    // padding: 90px 0;
    .header-text-blue {
        h1,
        h2,
        h5 {
            // font-family: map-get($font, bd);
            margin-top: 0px;
            // color: map-get($theme, 900);
            margin-bottom: 15px;
            font-size: calc(18px + (34 - 18) * ((100vw - 320px) / (1920 - 320)));
            span {
                font-family: map-get($font, rg);
            }
        }
        p {
            color: #333;
            line-height: 24px;
            font-family: map-get($font, md);
            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: #262d35;
            }
        }
        &.left {
            h6 {
                &:after {
                    margin: 0px;
                }
            }
        }
    }
    @media (max-width: 1440px) {
        padding: 50px 0;
        &--column {
            &__inner {
                padding: 50px 0;
            }
        }
    }
    @include media-breakpoint-only(lg) {
        padding: 50px 0;
        .header-text-blue {
            margin-bottom: 25px;
        }
    }
    @include media-breakpoint-only(md) {
        padding: 30px 0;
        .header-text-blue {
            margin-bottom: 20px;
        }
    }
    @include media-breakpoint-down(sm) {
        padding: 25px 0;
        .header-text-blue {
            margin-bottom: 15px;
            h1,
            h2,
            h5 {
                margin-bottom: 10px;
            }
            p {
                line-height: 22px;
            }
            h6 {
                margin-bottom: 25px;
            }
        }
    }
    @include media-breakpoint-down(xs) {
        .header-text-blue {
            h1,
            h2,
            h5 {
                line-height: 25px;
            }
        }
    }
    // Media End
}

.commonSection {
    padding: 70px 0;
    .commonSection_header {
        margin-bottom: 50px;
    }
    .commonSection_title {
        font-family: Raleway;
        font-weight: 600;
        color: #333;
        margin-bottom: 15px;
        font-size: calc(20px + (32 - 20) * ((100vw - 320px) / (1920 - 320)));
    }
    h1>span:not(.nocolor):not(.badge),
    h2>span:not(.nocolor):not(.badge),
    h3>span:not(.nocolor):not(.badge),
    h4>span:not(.nocolor):not(.badge),
    h5>span:not(.nocolor):not(.badge),
    h6>span:not(.nocolor):not(.badge) {
        color: #2878c8;
    }
}